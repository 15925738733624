import MenuIcon from '@mui/icons-material/Menu'
import { Box, Button, IconButton, Typography } from '@mui/material'
import Image from 'mui-image'
import { Link as LinkScroll } from 'react-scroll'
import { SocialIcon } from 'react-social-icons'
import styles from '../styles/Home.module.css'

export const NavbarButtonsForMobile = () => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography variant="h5" style={{ margin: '20px' }}>
        <a
          href="https://wiki.shibawars.net"
          target="_blank"
          style={{
            color: 'white',
            textDecoration: 'none'
          }}
        >
          Wiki
        </a>
      </Typography>
      <SocialIcon
        url="https://twitter.com/Shibawars"
        style={{ height: 40, width: 40, margin: '10px 10px' }}
        fgColor="white"
        target="_blank"
        rel="noopener noreferrer"
      />
      <SocialIcon
        url="https://discord.com/invite/mYdzEd67wY"
        style={{ height: 40, width: 40, margin: '20px 5px' }}
        fgColor="white"
        target="_blank"
        rel="noopener noreferrer"
      />
      {/* <SocialIcon
        url="https://www.reddit.com/r/shibawars/"
        style={{ height: 40, width: 40, margin: '10px 10px' }}
        fgColor="white"
        target="_blank"
        rel="noopener noreferrer"
      /> */}
      <SocialIcon
        url="https://t.me/shibawarsportal"
        style={{ height: 40, width: 40, margin: '10px 10px' }}
        network="telegram"
        fgColor="white"
        target="_blank"
        rel="noopener noreferrer"
      />
    </Box>
  )
}

export const NavbarButtonsForWeb = () => {
  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'block' }
      }}
    >
      <Box style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
        <Typography variant="h5" style={{ margin: '20px' }}>
          <a
            href="https://wiki.shibawars.net"
            target="_blank"
            style={{
              color: 'white',
              textDecoration: 'none'
            }}
          >
            Wiki
          </a>
        </Typography>
        <SocialIcon
          url="https://twitter.com/Shibawars"
          style={{ height: 40, width: 40, margin: '10px 10px' }}
          fgColor="white"
          target="_blank"
          rel="noopener noreferrer"
        />
        <SocialIcon
          url="https://discord.com/invite/mYdzEd67wY"
          style={{ height: 40, width: 40, margin: '20px 5px' }}
          fgColor="white"
          target="_blank"
          rel="noopener noreferrer"
        />
        {/* <SocialIcon
          url="https://www.reddit.com/r/shibawars/"
          style={{ height: 40, width: 40, margin: '10px 10px' }}
          fgColor="white"
          target="_blank"
          rel="noopener noreferrer"
        /> */}
        <SocialIcon
          url="https://t.me/shibawarsportal"
          style={{ height: 40, width: 40, margin: '10px 10px' }}
          network="telegram"
          fgColor="white"
          target="_blank"
          rel="noopener noreferrer"
        />
      </Box>
    </Box>
  )
}

export const Logo = () => {
  return (
    <Box style={{ display: 'flex' }}>
      {/* <Image src="/LOGO_SQUARE.webp" duration={1000} style={{ width: '50px' }} /> */}
      <Image
        src="/titles/labkatest2.png"
        duration={1000}
        style={{ width: '35px' }}
      />
    </Box>
  )
}

export const NavbarButtonsLandingPage = ({ links, toggleDrawer }) => {
  return (
    <Box style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 0 }}>
      <IconButton
        size="large"
        color="inherit"
        aria-label="menu"
        sx={{
          flexGrow: 0,
          display: { xs: 'block', sm: 'none' },
          justifyContent: 'flex-end',
          color: 'white'
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      {links.map((link) => (
        <Box
          key={link.href}
          sx={{
            flexGrow: 0,
            display: { xs: 'none', sm: 'block' },
            justifyContent: 'flex-end'
          }}
        >
          <LinkScroll to={link.href}>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', sm: 'block' },
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="outlined"
                className={`${styles.link} ${styles.links}`}
              >
                {link.name}
              </Button>
            </Box>
          </LinkScroll>
        </Box>
      ))}
    </Box>
  )
}
