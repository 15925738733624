import Box from '@mui/material/Box'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { Button, Link, useMediaQuery } from '@mui/material'
import Image from 'mui-image'

const DogeFather = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box style={{ maxWidth: '650px' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '15px 10px',
            justifyContent: 'center'
          }}
        >
          <Image src="/gangsters/1060021.webp" width={'auto'} height={'60vh'} />
        </Box>
        {/* <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="contained"
            sx={{
              margin: '10px',
              background: 'orange',
              fontSize: '2rem',
              textAlign: 'center',
              color: '#262626',
              fontWeight: 'bold',
              ':hover': {
                color: 'white',
                background: '#262626'
              }
            }}
            component={Link}
            href="https://mint.shibawars.net"
            target="blank"
          >
            MINT NOW!
          </Button>
        </Box> */}
      </Box>
    </Box>
  )
}

export default DogeFather
