import { Box } from '@mui/material'
import MainNavbar from './Navbar'

const Layout = ({ children }) => {
  return (
    <Box>
      <MainNavbar />
      <main>{children}</main>
    </Box>
  )
}

export default Layout
