import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'

const RoadMapFirstPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: matches ? 'column' : 'row'
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '15px'
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '10px' }}>
          October 2023
        </Typography>
        <Box
          style={{
            border: '2px solid #ffa509',
            padding: '10px',
            borderRadius: '1.0rem',
            width: '300px',
            margin: '15px',
            boxShadow: '0 0 16px rgba(0, 0, 0, 0.5)'
          }}
        >
          <Typography style={{ padding: '2.5px 30px' }}>
            More airdrops
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Final testing
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Launch season 1
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Work on season 2
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '15px'
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '10px' }}>
          After season 1
        </Typography>
        <Box
          style={{
            border: '2px solid #ffa509',
            padding: '10px',
            borderRadius: '1.0rem',
            width: '300px',
            margin: '15px',
            boxShadow: '0 0 16px rgba(0, 0, 0, 0.5)'
          }}
        >
          <Typography style={{ padding: '2.5px 30px' }}>
            Prepare season 2
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>New content</Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Multiple languages
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Telegram bot
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default RoadMapFirstPage
