import Box from '@mui/material/Box'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import { TeamIcon } from '../icons/titles/Team'
import 'aos/dist/aos.css'
import Kriko from '../components/teamPage/Kriko'
import Kaya from '../components/teamPage/Kaya'
import Chvister from '../components/teamPage/Chvister'

const TeamPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesTitle = useMediaQuery('(min-height:800px)')

  return (
    <Box className="section">
      <Box
        sx={{
          marginBottom: '20px',
          display: matchesSM ? 'none' : 'flex',
          justifyContent: 'center',
          position: 'relative',
          top: matchesSM ? '0' : '6rem'
        }}
      >
        {matchesTitle && <TeamIcon />}
      </Box>

      <Box
        className="slide"
        style={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: matchesSM ? '7.5%' : matches ? '10%' : '6%'
        }}
      >
        <Box
          style={{
            display: 'flex'
          }}
        >
          <Kriko />
          {!matches && (
            <>
              <Kaya />
              <Chvister />
            </>
          )}
          {}
        </Box>
      </Box>
      <Box
        className="slide"
        style={{
          display: matches ? 'flex' : 'none',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: matchesSM ? '7.5%' : matches ? '10%' : '6%'
        }}
      >
        <Kaya />
      </Box>
      <Box
        className="slide"
        style={{
          display: matches ? 'flex' : 'none',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: matchesSM ? '7.5%' : matches ? '10%' : '6%'
        }}
      >
        <Chvister />
      </Box>
    </Box>
  )
}

export default TeamPage
