import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
// A custom theme for this app

const defaultTheme = createTheme()

const theme = createTheme({
  ...defaultTheme,
  typography: {
    fontWeightLight: 400,
    fontWeightBold: 400,
    body1: {
      fontSize: '1.2rem'
    },
    h2: {
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '3rem'
      }
    },
    h4: {
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.9rem'
      }
    },
    fontFamily: [
      'Menlo',
      'Monaco',
      'Lucida Console',
      'Liberation Mono',
      'DejaVu Sans Mono',
      'Bitstream Vera Sans Mono',
      'Courier New, monospace'
    ]
  },
  palette: {
    primary: {
      main: '#ffa509'
    },
    secondary: {
      main: '#ff8400'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fff'
    }
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'inlineBlock',
          position: 'relative',
          width: 25,
          height: 25,
          float: 'right',
          margin: 'auto',
          color: '#fff'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#262626',
          margin: '20px'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: '#ffa509',
          color: '#fff'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#fff'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        fontFamily: [
          'Menlo',
          'Monaco',
          'Lucida Console',
          'Liberation Mono',
          'DejaVu Sans Mono',
          'Bitstream Vera Sans Mono',
          'Courier New, monospace'
        ]
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff'
        },
        shrink: {
          color: '#ffa509'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 165, 9, 1)'
          },
          '&:hover > .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 165, 9, 1)'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#fff'
          }
        },
        outlined: {
          color: 'white',
          border: 'none',
          fontSize: '1.1rem',
          padding: '5px 5px 0px 5px',
          lineHeight: 1.5,
          textAlign: 'center',
          margin: '0.8rem',
          ':hover': {
            border: 'none'
          }
        },
        textInfo: {
          width: '150px',
          height: '40px',
          background: 'rgba(255, 165, 9, 1)',
          boxShadow: '0 0 12px rgb(0 0 0 / 60%)',
          color: 'white',
          ':hover': {
            background: 'rgba(255, 165, 9, 1)'
          }
        },
        contained: {
          width: '230px',
          height: '50px',
          background: 'rgba(255, 165, 9, 1)',
          boxShadow: '0 0 12px rgb(0 0 0 / 60%)',
          color: 'white',
          ':hover': {
            background: 'rgba(255, 165, 9, 1)'
          }
        }
      }
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTextField: {
      contained: {
        color: 'red',
        background: 'red'
      }
    },
    MuiButton: {}
  }
})

export default theme
