import Box from '@mui/material/Box'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import { RoadMapIcon } from '../icons/titles/Roadmap'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import RoadMapFirstPage from '../components/roadmapPage/RoadMapFirstPage'
import RoadMapSecondPage from '../components/roadmapPage/RoadMapSecondPage'

const RoadmapPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const matchesTitle = useMediaQuery('(min-height:800px)')

  return (
    <Box
      className="section"
      sx={{
        backgroundImage: "url('./background.webp')",
        backgroundPosition: { sm: 'left', md: 'center' },
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh'
      }}
    >
      <Box
        sx={{
          marginBottom: '20px',
          display: matches ? 'none' : 'flex',
          justifyContent: 'center',
          position: 'relative',
          top: matches ? '0' : '6rem'
        }}
      >
        {matchesTitle && <RoadMapIcon />}
      </Box>
      <Box
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '7%'
        }}
      >
        <RoadMapFirstPage />
        <RoadMapSecondPage show={matches ? false : true} />
      </Box>
      <Box
        display={matches ? 'flex' : 'none'}
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '160px'
        }}
      >
        <RoadMapSecondPage show={matches ? true : false} />
      </Box>
    </Box>
  )
}

export default RoadmapPage
