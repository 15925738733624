import { useState } from 'react'
import { NavbarButtonsForMobile, NavbarButtonsForWeb } from './helpers'
import {
  AppBar,
  Box,
  Toolbar,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ShibawarsLogo } from '../icons/logo/shibawars'
import MenuIcon from '@mui/icons-material/Menu'
import Image from 'mui-image'

const MainNavbar = () => {
  const [state, setState] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const toggleDrawer = (open) => (event) => {
    setState(open)
  }

  return (
    <>
      <AppBar
        component="nav"
        style={{ display: 'flex', background: 'transparent' }}
      >
        <Toolbar
          style={{ justifyContent: 'space-between', margin: '0px 15px' }}
        >
          <Box style={{ display: 'flex' }}>
            {matches ? (
              <ShibawarsLogo height={50} color="orange" />
            ) : (
              <Image
                src="/LOGO_ROUND.webp"
                duration={1000}
                style={{ width: '55px' }}
              />
            )}
          </Box>
          <IconButton
            size="large"
            aria-label="menu"
            sx={{
              flexGrow: 0,
              display: { xs: 'block', sm: 'none' },
              justifyContent: 'flex-end',
              color: 'white',
              padding: '0'
              //fontSize:'50px'
            }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon style={{ fontSize: '30px' }} />
          </IconButton>
          <Drawer
            anchor={'top'}
            open={state}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                backgroundColor: '#262626',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }
            }}
          >
            <IconButton
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'white',
                margin: '0px 20px'
              }}
              aria-label="delete"
              size="large"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon style={{ fontSize: '35px' }} />
            </IconButton>
            <NavbarButtonsForMobile />
          </Drawer>
          <NavbarButtonsForWeb />
        </Toolbar>
      </AppBar>
    </>
  )
}

export default MainNavbar
