import Box from '@mui/material/Box'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import Prizepool from '../components/aboutPage/Prizepool'
import { AboutIcon } from '../icons/titles/About'
import Challenges from '../components/aboutPage/Challenges'
import Burn from '../components/aboutPage/Burn'
import Fight from '../components/aboutPage/Fight'
import Conquer from '../components/aboutPage/Conquer'
import Collect from '../components/aboutPage/Collect'

const AboutPage = ({ setScrolling }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesTitle = useMediaQuery('(min-height:800px)')

  return (
    <Box
      className="section"
      sx={{
        backgroundImage: "url('./background.webp')",
        backgroundPosition: { sm: 'left', md: 'center' },
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh'
        //display: 'flex'
      }}
    >
      <Box
        sx={{
          marginBottom: '20px',
          display: matches ? 'none' : 'flex',
          justifyContent: 'center',
          position: 'relative',
          top: matches ? '0' : '8rem'
        }}
      >
        {matchesTitle && <AboutIcon />}
      </Box>
      {/* <Box
        style={{
          display: 'flex'
        }}
      > */}
      <Box
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '100px'
        }}
      >
        <Prizepool />
      </Box>
      <Box
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '100px'
        }}
      >
        <Challenges />
      </Box>
      <Box
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '100px'
        }}
      >
        <Burn />
      </Box>
      <Box
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '100px'
        }}
      >
        <Fight />
      </Box>
      <Box
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '100px'
        }}
      >
        <Conquer />
      </Box>
      <Box
        className="slide"
        style={{
          justifyContent: matches ? 'none' : 'flex-start',
          marginTop: matches ? '0px' : '100px'
        }}
      >
        <Collect />
        {/* </Box> */}
      </Box>
    </Box>
  )
}

export default AboutPage
