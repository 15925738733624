import LandingPageWrapper from './LandingPageWrapper'
import { Analytics } from '@vercel/analytics/react'
import { ThemeProvider } from '@mui/material/styles'
import ReactDOM from 'react-dom/client'
import theme from '../src/theme'
import './styles/index.css'
import React from 'react'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <LandingPageWrapper />
      <Analytics />
    </React.StrictMode>
  </ThemeProvider>
)
