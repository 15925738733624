import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import Image from 'mui-image'

const Prizepool = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '560px'
      }}
    >
      <Box style={{ maxWidth: '650px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: matches ? '0px 15px' : '30px 15px',
            justifyContent: 'center'
          }}
        >
          <Image
            src="/pawlinski.webp"
            width={matches ? 'auto' : '400px'}
            //height={'50vh'}
          />
        </Box>
        <Box
          sx={{
            padding: '20px',
            textAlign: 'center'
          }}
        >
          <Typography variant={'h4'}>$2000 INITIAL PRIZEPOOL</Typography>
          <Typography>Top players will share 55% of the prizepool!</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Prizepool
