import Box from '@mui/material/Box'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { Button, Link, useMediaQuery } from '@mui/material'
import { AboutIcon } from '../icons/titles/About'
import Warrior from '../components/dogsPage/Warrior'
import Warlord from '../components/dogsPage/Warlord'
import Floki from '../components/dogsPage/Floki'
import OG from '../components/dogsPage/OG'
import Ryoshi from '../components/dogsPage/Ryoshi'
import DogeFather from '../components/dogsPage/DogeFather'
import Whale from '../components/dogsPage/Whale'
import Woofmeister from '../components/dogsPage/Woofmeister'

const DogsPage = ({ setScrolling }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesTitle = useMediaQuery('(min-height:800px)')

  return (
    <Box
      className="section"
      sx={{
        backgroundImage: "url('./BG_DOGS.webp')",
        backgroundPosition: { sm: 'right', md: 'center' },
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <Woofmeister />
        </Box>
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <Whale />
        </Box>
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <DogeFather />
        </Box>
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <Ryoshi />
        </Box>
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <OG />
        </Box>
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <Floki />
        </Box>
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <Warlord />
        </Box>
        <Box
          className="slide"
          style={{
            justifyContent: matches ? 'none' : 'flex-start',
            marginTop: matches ? '0' : '4rem'
          }}
        >
          <Warrior />
        </Box>
        <Button
          variant="contained"
          sx={{
            margin: '10px',
            background: 'orange',
            fontSize: '2rem',
            textAlign: 'center',
            color: '#262626',
            position: 'relative',
            ':hover': {
              color: 'white',
              background: '#262626'
            }
          }}
          component={Link}
          href="https://mint.shibawars.net"
          target="blank"
        >
          MINT NOW!
        </Button>
      </Box>
    </Box>
  )
}

export default DogsPage
