import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'aos/dist/aos.css'
import { SocialIcon } from 'react-social-icons'
import Image from 'mui-image'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

const Chvister = () => {
  return (
    <Box
      sx={{
        width: '16rem',
        height: ' 32.5rem',
        boxShadow: '0 0 12px rgba(0, 0, 0, 0.2)',
        margin: '3px 15px',
        ':hover': {
          boxShadow: ' 0 0 12px rgba(255, 165, 9, 0.4)'
        }
      }}
    >
      <Box>
        <Image
          style={{ height: '16.5rem' }}
          src="/team/chvister.webp"
          alt="chvister"
        />
        <Box style={{ margin: '15px', textAlign: 'center' }}>
          <Typography variant="h5">Chvister</Typography>
          <Typography style={{ fontSize: '0.9rem' }}>
            Full-stack developer and crypto enthusiast. Worked on the Shibawars
            front-end.
          </Typography>
          <Box
            style={{
              marginTop: '30%'
              //   position: 'absolute',
              //   left: '50%',
              //   right: '0',
              //   bottom: '10px'
            }}
          >
            <SocialIcon
              url="https://twitter.com/chvisterko"
              style={{ height: 40, width: 40, margin: '5px' }}
              fgColor="white"
              target="_blank"
              rel="noopener noreferrer"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Chvister
