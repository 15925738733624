import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'aos/dist/aos.css'
import { SocialIcon } from 'react-social-icons'
import Image from 'mui-image'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

const Kriko = () => {
  return (
    <Box
      sx={{
        width: '16rem',
        height: ' 32.5rem',
        boxShadow: '0 0 12px rgba(0, 0, 0, 0.2)',
        margin: '3px 15px',
        ':hover': {
          boxShadow: ' 0 0 12px rgba(255, 165, 9, 0.4)'
        }
      }}
    >
      <Box>
        <Image style={{ height: '16.5rem' }} src="/team/kriko.webp" />
        <Box style={{ margin: '15px', textAlign: 'center' }}>
          <Typography variant="h5" style={{}}>
            Kriko
          </Typography>
          <Typography style={{ fontSize: '0.9rem' }}>
            Creator of Shibawars. Programming, crypto, blockchain, and
            decentralization fanatic. Worked on the smart contracts and the idea
            of Shibawars.
          </Typography>
          <Box
            style={
              {
                //   position: 'absolute',
                //   left: '0',
                //  // right: '50%',
                //   bottom: '10px'
              }
            }
          >
            <SocialIcon
              url="https://twitter.com/krikoeth"
              style={{ height: 40, width: 40, margin: '5px' }}
              fgColor="white"
              target="_blank"
              rel="noopener noreferrer"
            />
            <SocialIcon
              url="https://www.linkedin.com/in/dominik-kr%C3%AD%C5%BEo-7a4677138/"
              style={{ height: 40, width: 40, margin: '5px' }}
              fgColor="white"
              target="_blank"
              rel="noopener noreferrer"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Kriko
