import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import MainPage from './pages/MainPage'
import AboutPage from './pages/AboutPage'
import RoadmapPage from './pages/RoadmapPage'
import Layout from './navbar'
import TeamPage from './pages/TeamPage'
import ReactFullpage from '@fullpage/react-fullpage'
import './styles/overrides.css'
import DogsPage from './pages/DogsPage'

const LandingPageWrapper = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Layout>
      <ReactFullpage
        anchors={['intro', 'dogs', 'about', 'roadmap', 'team']}
        licenseKey={'PL70I-R4U0H-I678H-747K9-WYLZR'}
        scrollingSpeed={1000}
        scrollOverflowResetKey={'38D225E1-61FA4EA3-BE6A0E09-71526B6C'}
        scrollOverflowReset={true}
        controlArrows={matches ? false : true}
        slidesNavigation={true}
        slidesNavPosition="bottom"
        scrollOverflow={true}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              <MainPage />
              <DogsPage />
              <AboutPage />
              <RoadmapPage />
              <TeamPage />
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </Layout>
  )
}

export default LandingPageWrapper
