import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { Button, Grid, Link, useMediaQuery } from '@mui/material'
import { ShibawarsLogo } from '../icons/logo/shibawars'

const MainPage = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const breakMd = useMediaQuery(theme.breakpoints.down('md'))
  const breakLG = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box
      id="page1"
      className="section"
      style={{
        backgroundImage: 'url("elrey.webp")',
        backgroundPosition: breakLG ? ' 70%' : '100%',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(255,165,9,1)',
        height: '100vh' /* 100% of the viewport height */,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid
        container
        spacing={4}
        style={{
          width: '70%'
        }}
      >
        <Grid item xs={10}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              margin: '30px'
            }}
          >
            {!breakMd && <ShibawarsLogo height={150} color="black" />}
            <Typography
              variant="h3"
              style={{
                marginTop: '20px',
                background: matches ? 'rgba(0,0,0,0.2)' : 'transparent',
                padding: matches ? '10px' : '0'
              }}
            >
              Unleash your inner gangster!
            </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: matches ? 'column' : 'row',
                alignItems: 'flex-start',
                margin: '30px'
              }}
            >
              <Button
                variant="contained"
                sx={{
                  marginTop: '60px',
                  marginRight: '30px',
                  background: '#262626',
                  fontSize: '2rem',
                  ':hover': {
                    color: 'white',
                    background: '#262626'
                  }
                }}
                component={Link}
                href="https://mint.shibawars.net"
                target="blank"
              >
                MINT NOW!
              </Button>
              <Button
                variant="contained"
                sx={{
                  marginTop: '60px',
                  background: '#262626',
                  fontSize: '2rem',
                  ':hover': {
                    color: 'white',
                    background: '#262626'
                  }
                }}
                component={Link}
                href="https://app.shibawars.net"
                target="blank"
              >
                PLAY NOW!
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Box>
  )
}

export default MainPage
