import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import Image from 'mui-image'

const Collect = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '560px'
      }}
    >
      <Box style={{ maxWidth: '650px' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: matches ? '0px 15px' : '30px 15px',
            justifyContent: 'center'
          }}
        >
          <Image src="/collect.webp" width={matches ? 'auto' : '400px'} />
        </Box>
        <Box
          sx={{
            padding: '20px',
            textAlign: 'center'
          }}
        >
          <Typography variant={'h4'}>COLLECT RARE SHIBA GANGSTERS</Typography>
          <Typography>Show off your real gangster spirit!</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Collect
