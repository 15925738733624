import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import 'aos/dist/aos.css'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mui/material'

const RoadMapSecondPage = ({show}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      style={{
        display:  show ? 'flex' :'none',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: matches ? 'column' : 'row'
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '15px'
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '10px' }}>
          2021 - 2022
        </Typography>
        <Box
          style={{
            border: '2px solid #ffa509',
            padding: '10px',
            borderRadius: '1.0rem',
            width: '300px',
            margin: '15px',
            boxShadow: '0 0 16px rgba(0, 0, 0, 0.5)'
          }}
        >
          <Typography style={{ padding: '2.5px 30px' }}>
            Development started
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Launch the website
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Social networks
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Release whitepaper
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '15px'
        }}
      >
        <Typography variant="h4" style={{ marginBottom: '10px' }}>
          Early 2023
        </Typography>
        <Box
          style={{
            border: '2px solid #ffa509',
            padding: '10px',
            borderRadius: '1.0rem',
            width: '300px',
            margin: '15px',
            boxShadow: '0 0 16px rgba(0, 0, 0, 0.5)'
          }}
        >
          <Typography style={{ padding: '2.5px 30px' }}>
            Alpha testing
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Community airdrops
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Launch website V2
          </Typography>
          <Typography style={{ padding: '2.5px 30px' }}>
            Community building
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default RoadMapSecondPage
